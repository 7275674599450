import React from 'react';
import { ListViewHeader } from '@progress/kendo-react-listview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@progress/kendo-react-buttons';

/**
 * Header Render
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Header component.
 */
export const HeaderRender = (props) => {
  return (
    <ListViewHeader className='listview-header'>{props.title}</ListViewHeader>
  );
};

/**
 * ListViewItemRender
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Item Render component.
 */
export const ListViewItemRender = ({ dataItem, handleGenerateQRCode }) => {
  let item = dataItem;
  return (
    <div className='k-listview-item profile-listview'>
      <div className='setup-col'>
        <div className='k-d-flex'>
          <h6 className='setup-name'>{item?.SRFProfilesSetupName}</h6>
        </div>
        <div className='k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base k-ml-2'>
          <div className='k-chip-content'>{item?.SRFProfilesName}</div>
        </div>
      </div>
      <div className='action-col'>
        <Button
          className={'qr-button'}
          onClick={() => handleGenerateQRCode(item)}
          themeColor={'primary'}
          size={'small'}
        >
          <FontAwesomeIcon icon={faQrcode} size='lg' title='Generate QR' />
        </Button>
      </div>
    </div>
  );
};
export default ListViewItemRender;
